import React, { useEffect, useState } from "react";
import * as VoxImplant from "voximplant-websdk";

import dayjs from "dayjs";
import "dayjs/locale/ru";

import { ClientState } from "voximplant-websdk";
import { Client } from "voximplant-websdk/Client";
import { Call } from "voximplant-websdk/Call/Call";
import { Image, Row, Typography } from "antd";
import {
	onCallConnected,
	onCallDisconnected,
	onCallFailed,
	handleInfoReceived,
} from "../CallHandlers";
import micro from "../assets/small_mic.svg";
import person from "../assets/person.svg";
import micro_disable from "../assets/small_mic_cross.svg";
import { TMKChatWidget } from "../components/TMKChatWidget/TMKChatWidget";
import styles from "../components/styles.module.scss";
import back_icon from "../assets/back_icon.svg";
import danger from "../assets/danger.svg";
import useModal from "../hooks/useModal";
import StubImage from "src/assets/MessageStub.png";

import {
	useGetCallStateFrequencyQuery,
	useGetUserInfoQuery,
	useLazyGetOneTimePassQuery
} from "../redux/api";

import useHashOneTimeKey from "src/hooks/useHashOneTimeKey";

import Controls from "./Controls";
import { skipToken } from "@reduxjs/toolkit/query";

import Timer from "./Timer";
import Countdown from "./Countdown";
import NotOnlineNoteToDoctor from "./NotOnlineNotes/NotOnlineNoteToDoctor";
import NotOnlineNoteToPatient from "./NotOnlineNotes/NotOnlineNoteToPatient";
import LoadingAnimation from "./LoadingAnimation/LoadingAnimation";
import CallFailedNote from "./NotOnlineNotes/CallFailedNote";

const VoxImplantComponent: React.FC = () => {
	const [onlineStatus, setOnlineStatus] = useState<"online" | "offline">("online");
	const [micStatus, setMicStatus] = useState<"micEnabled" | "micDisabled">("micEnabled");
	const [showCancelButton, setShowCancelButton] = useState(false);
	const [mic, setMic] = useState(true);
	const { setShowModal, openModal } = useModal();
	const [currentCall, setCurrentCall] = useState<Call | null>(null);
	const [isCameraAvailable, setCameraAvailable] = useState<boolean | null>(null);
	const [showLog, setShowLog] = useState<boolean>(true);

	const [remoteVideoAvailable, setRemoteVideoAvailable] = useState(false);

	const [patientVideoOn, setPatientVideoOn] = useState(true);
	const [panelTitle, setPanelTitle] = useState("");

	const [displayName, setDisplayName] = useState("");
	const [errorAlert, setErrorAlert] = useState(false);
	const { sessionId, UUID } = useHashOneTimeKey();
	const [isCameraAccessGranted, setIsCameraAccessGranted] = useState<boolean | null>(null);
	const [isMicrophoneAccessGranted, setIsMicrophoneAccessGranted] = useState<boolean | null>(null);
	const [clientState, setClientState] = useState<ClientState | null>(null);
	const [isClientStateReady, setIsClientStateReady] = useState(false);
	const [oneTimePasswordRequested, setOneTimePasswordRequested] = useState(false);
	const [showCallButton, setShowCallButton] = useState(false);
	const [callStatus, setCallStatus] = useState<"active" | "failed" | "start" | "hangup">("start");
	const [callFailed, setCallFailed] = useState(false);
	const [intervalItem, setIntervalItem] = useState<any>(null);
	const {
		data: userInfoData,
		error: userInfoError,
		isLoading: userInfoLoading,
	} = useGetUserInfoQuery(
		sessionId && UUID
			? {
					sessionId: sessionId,
					appointmentId: UUID,
			  }
			: skipToken,
	);
	const { userId, login: username, name } = userInfoData?.user || {};

	const { specialityServiceName, startDate: startDateString } = userInfoData?.appointment || {};
	const startDate = startDateString ? new Date(startDateString).getTime() : 0;
	dayjs.locale("ru");
	const formattedDateTime = dayjs(startDate).locale("ru").format("D MMMM YYYY в HH:mm");

	const callTo = userInfoData?.callTo;
	const callToName = userInfoData?.callToName;
	const room = userInfoData?.roomId;

	const isPatient = userInfoData?.roleInAppointment === "patient";

	const [getOneTimePass, { error, data, isLoading }] = useLazyGetOneTimePassQuery();
	const {data: frequency} = useGetCallStateFrequencyQuery({});

	// function updateCallStatus(status: "active" | "failed" | "start") {
	// 	setCallStatus(status);
	// }
	const handleCancelCall = () => {
		if (currentCall) {
			currentCall.hangup();
			setShowCancelButton(false);
			setCurrentCall(null);
		} else {
			console.error(
				"Unable to hang up the call. Current call or hangup function is not available.",
			);
		}
	};

	const voxAPI = VoxImplant.getInstance();

	const onAuthResult = ({ result, username }: { result: boolean; username: string }) => {
		if (result) {
			updatePanelTitle(username);
			showLocalVideo(true);
			setErrorAlert(false);
		} else {
			setErrorAlert(true);
		}
	};

	function updatePanelTitle(username: string) {
		setPanelTitle(`Вы вошли как ${username}`);
	}
	function sendVideo(flag: boolean) {
		currentCall?.sendVideo(flag);
		setRemoteVideoAvailable(flag);
	}

	function showLocalVideo(flag: boolean) {
		voxAPI.showLocalVideo(flag);
	}

	function showRemoteVideo(flag: boolean) {
		setPatientVideoOn(flag);
		setRemoteVideoAvailable(flag);
	}

	function onLocalVideoStream(e: React.FormEvent<HTMLFormElement>) {
		if (e.type === "sharing") {
			const shareButton = document.getElementById("shareButton");
			if (shareButton) {
				shareButton.innerHTML = "Stop Sharing";
				shareButton.addEventListener("click", () => {
					currentCall?.stopSharingScreen();
					shareButton.innerHTML = "Share Screen";
					shareButton.addEventListener("click", () => {
						currentCall?.shareScreen(true);
					});
				});
			}
		}
	}
	const handleInfoReceivedWrapper = (e: any) => {
		handleInfoReceived(e, setMicStatus);
	};
	const onCallDisconnectedWrapper = (e: any) => {
		console.log("onCallDisconnectedWrapper called, currentCall:", currentCall);
		onCallDisconnected(e, setCurrentCall, setShowCancelButton, setOnlineStatus, setCallStatus);
		setShowCancelButton(false);
		setShowCallButton(true);
		setCurrentCall(null);
		setCallStatus("hangup");
	};
	const handleBackClick = () => {
		window.top?.postMessage('CloseVcs', '*');
	};
	useEffect(() => {
		let intervalId: NodeJS.Timeout;

		const checkCameraAvailability = async () => {
			try {
				console.log("Попытка доступа к камере...");
				const stream = await navigator.mediaDevices.getUserMedia({ video: true });
				console.log("Камера доступна");
				setCameraAvailable(true);
				setIsCameraAccessGranted(true);
			} catch (error) {
				console.error("Ошибка при попытке доступа к камере:");
				console.log("Камера недоступна");
				setCameraAvailable(false);
				setIsCameraAccessGranted(false);
			}
		};
		const checkMicrophoneAvailability = async () => {
			try {
				console.log("Попытка доступа к микрофону...");
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				console.log("Микрофон доступен");
				setIsMicrophoneAccessGranted(true);
			} catch (error) {
				console.error("Ошибка при попытке доступа к микрофону:");
				console.log("Микрофон недоступен");
				setIsMicrophoneAccessGranted(false);
			}
		};
		const checkAvailability = async () => {
			await checkCameraAvailability();
			await checkMicrophoneAvailability();
		};
		checkAvailability();

		//checkCameraAvailability();
		if (showLog) {
			const logElement = document.getElementById("log");
			if (logElement) {
				logElement.classList.remove("hidden");
			}
		}
		if (username === undefined) {
			return;
		}
		const voxAPI = VoxImplant.getInstance();
		const mode = "webrtc";

		const onSDKReady = (voxAPI: Client) => {
			console.log("onSDKReady version " + voxAPI.version);
			console.log("WebRTC supported: " + voxAPI.isRTCsupported());
		};

		const onConnectionFailed = (voxAPI: Client) => {
			console.log("onCallDisconnectedWrapper called, currentCall:", currentCall);
			console.log("Connection failed");
			setTimeout(() => {
				voxAPI.connect();
			}, 1000);
			setCallStatus("failed");
		};

		const onConnectionClosed = (voxAPI: Client) => {
			console.log("Connection closed");
			setTimeout(() => {
				voxAPI.connect();
			}, 1000);
		};

		const onIncomingCall = (e: { call: Call }) => {
			let newCall = e.call;

			newCall.on(VoxImplant.CallEvents.Connected, () =>
				onCallConnected(newCall, setShowCancelButton, sendVideo, showRemoteVideo, setCallStatus),
			);
			newCall.on(VoxImplant.CallEvents.Disconnected, () =>
				onCallDisconnectedWrapper(setCurrentCall),
			);
			newCall.on(VoxImplant.CallEvents.Failed, () =>
				onCallFailed(setCurrentCall, setShowCancelButton, createCall, setCallFailed, setCallStatus),
			);

			newCall.on(VoxImplant.CallEvents.InfoReceived, handleInfoReceivedWrapper);

			console.log("Входящий звонок от: " + newCall.number());

			newCall.answer("", {}, { receiveVideo: true, sendVideo: true });
			setCurrentCall(newCall);
			setShowCancelButton(true);
		};
		function onMicAccessResult(e: React.FormEvent<HTMLFormElement>) {
			const formElement = e.currentTarget;
			const micAccessResult = formElement.checkValidity();
			console.log(`Mic/Cam access allowed`);
			if (micAccessResult) {
				if (mode === "webrtc") setShowModal(false);
			} else {
				console.log("Для использования сервиса пожалуйста предоставьте доступ к микрофону");
			}
		}

		const voximplantUser = `${username}@test.irisskvo.n3.voximplant.com`;
		const displayName = currentCall?.displayName();
		{
			displayName && setDisplayName(displayName);
		}
		const onConnectionEstablished = (voxAPI: Client) => {
			console.log("Connection established");
			openModal();
			// voxAPI.login(username, data?.key);
			// setInterval(() => {
			// 	const currentState = voxAPI.getClientState();

			// 	setClientState(currentState);
			// 	setIsClientStateReady(true);
			// }, 1000);
			// voxAPI.on(VoxImplant.CallEvents.Failed, () => onCallFailed(setCurrentCall, setCallStatus));
			// voxAPI.on(VoxImplant.CallEvents.Connected, () => setCallStatus("active"));
		};

		voxAPI.on(VoxImplant.Events.SDKReady, () => {
			onSDKReady(voxAPI);
		});
		voxAPI.on(VoxImplant.Events.ConnectionEstablished, () => {
			onConnectionEstablished(voxAPI);
		});
		voxAPI.on(VoxImplant.Events.ConnectionFailed, () => {
			onConnectionFailed(voxAPI);
		});
		voxAPI.on(VoxImplant.Events.ConnectionClosed, () => {
			onConnectionClosed(voxAPI);
		});
		voxAPI.on(VoxImplant.Events.AuthResult, (e) => {
			onAuthResult(e);
		});
		voxAPI.on(VoxImplant.Events.IncomingCall, (e) => {
			onIncomingCall(e);
		});
		voxAPI.on(VoxImplant.Events.MicAccessResult, (e) => {
			onMicAccessResult(e);
		});

		try {
			//@ts-ignore
			const ACCOUNT_NODE: any = VoxImplant.ConnectionNode[window.__RUNTIME_CONFIG__.REACT_APP_VOXIMPLANT_NODE];
			voxAPI.init({
				micRequired: true,
				videoSupport: true,
				progressTone: true,
				localVideoContainerId: "voximplant_local",
				remoteVideoContainerId: "voximplant_remote",
				node: ACCOUNT_NODE
				// showDebugInfo: true,
			});
		} catch (e) {
			console.log(e);
		}

		voxAPI.connect().then(() => voxAPI.requestOneTimeLoginKey(voximplantUser));
		voxAPI.on(VoxImplant.Events.AuthResult, (e) => {
			if (e.result) {
				// Успешная аутентификация
			} else if (e.code == 302) {
				if (!oneTimePasswordRequested) {
					setOneTimePasswordRequested(true); // Установите флаг oneTimePasswordRequested в true
					userId &&
						getOneTimePass({ voximplantId: userId, loginKey: e.key })
							.unwrap()
							.then((response) => {
								response.oneTimePassword &&
									voxAPI.loginWithOneTimeKey(voximplantUser, response.oneTimePassword);
							});
				} else {
					console.log("Одноразовый пароль уже был запрошен, пропускаем повторный вызов.");
				}
			}
		});
	}, [username, currentCall]);

	function createCall(): void {
		if (callTo) {
			console.log("Calling to " + callTo);
			let newCall = voxAPI.call({
				number: callTo,
				video: { receiveVideo: true, sendVideo: true },
				customData: "TEST CUSTOM DATA",
			});

			newCall.on(VoxImplant.CallEvents.Connected, () =>
				onCallConnected(newCall, setShowCancelButton, sendVideo, showRemoteVideo, setCallStatus),
			);
			newCall.on(VoxImplant.CallEvents.Disconnected, (e) =>
				onCallDisconnectedWrapper(setCurrentCall),
			);

			newCall.on(VoxImplant.CallEvents.Failed, () =>
				onCallFailed(
					setCurrentCall,
					setCallStatus,
					setCallFailed,
					setShowCallButton,
					setShowCancelButton,
				),
			);

			//newCall.on(VoxImplant.CallEvents.Failed, onCallFailed);
			newCall.on(VoxImplant.CallEvents.InfoReceived, handleInfoReceivedWrapper);

			setCurrentCall(newCall);
			setShowCancelButton(true);
			setCallStatus("active");
		}
	}

	useEffect(() => {
		if (currentCall !== null && frequency && startDate && !isPatient) {
			if (intervalItem === null) {
				const interval = setInterval(() => {
					fetch(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/voximplant/call-state-record`, {
						method: 'POST',
						headers: {"Content-Type": "application/json"},
						body: JSON.stringify({
							appointmentId: UUID,
							startDate: dayjs(startDate).toISOString(),
							frequency: frequency
						})
					}).then().catch((error) => console.warn(error));
				}, frequency * 1000);
				setIntervalItem(interval);
			}
		} else if (intervalItem) {
			clearInterval(intervalItem);
			setIntervalItem(null);
		}
	}, [currentCall, startDate, frequency, intervalItem]);
	
	useEffect(() => {
		console.log("callStatus:", callStatus);
		console.log(callFailed);
	}, [callStatus, callFailed]);
	console.log("callStatus:", callStatus);
	console.log(callFailed);
	return (
		<>
			<div className="voximplant_wrapper">
				<div id="voximplant_container">
					<div className="column">
						<div className="meeting_info">
							<div className="entry_wrapper">
								{isPatient && (
									<button className="back-button" onClick={handleBackClick}>
										<img className="back_icon" src={back_icon} alt="main" />
										Назад
									</button>
								)}
								<div className="entry_start">
									<Countdown startDateString={startDateString} />
								</div>
							</div>
							<div className="entry_name">{specialityServiceName}</div>

							<div className="entry_date_time">{formattedDateTime}</div>
						</div>
						<div id="voximplant_local">
							{error && <p>Ошибка при получении одноразового пароля</p>}
							{userInfoLoading && <p>Загрузка информации о пользователе...</p>}
							{userInfoError && <p>Ошибка при получении информации о пользователе</p>}
							{!userInfoData && !userInfoLoading && !userInfoError && (
								<p>Информация о пользователе не найдена</p>
							)}

							<div className="initials_local">
								{name}{" "}
								{mic ? (
									<img className="initials_micro" src={micro} alt="main" />
								) : (
									<img className="initials_micro" src={micro_disable} alt="main" />
								)}
							</div>
							<div className="empty">
								<>
									<div className="person_circle_local">
										<img className="person_local" src={person} alt="main" />
									</div>
								</>
							</div>
						</div>
						<div className="duration">
							{" "}
							{currentCall ? (
								<Timer currentCall={currentCall} handleCancelCall={handleCancelCall} />
							) : (
								<span>00:00:00</span>
							)}
						</div>
					</div>
					<div className="second_column">
						<div id="voximplant_remote">
							<div className="initials_remote">
								{callToName}{" "}
								{micStatus === "micEnabled" ? (
									<img className="initials_micro" src={micro} alt="main" />
								) : (
									<img className="initials_micro_disable" src={micro_disable} alt="main" />
								)}
							</div>

							<div className={`empty${isPatient ? ' empty_patient' : ''}`}>
								{onlineStatus !== "online" && (
									<p className={styles.NotOnlineNote}>
										{isPatient ? <NotOnlineNoteToPatient /> : <NotOnlineNoteToDoctor />}
									</p>
								)}
								{currentCall && (
									<div className="person_circle">
										<img className="person" src={person} alt="main" />
									</div>
								)}
								{!currentCall && onlineStatus === "online" && (
									<>
										{callStatus === "failed" &&
											isMicrophoneAccessGranted &&
											isCameraAccessGranted && <CallFailedNote />}

										{callStatus === "start" && (
											<>
												<div className="waiting_line">
													{isMicrophoneAccessGranted && isCameraAccessGranted && (
														<>
															<LoadingAnimation />
															{isPatient
																? "Пожалуйста, ожидайте подключения врача"
																: "Здесь будет видео пациента"}
														</>
													)}
												</div>
												{(!isMicrophoneAccessGranted || !isCameraAccessGranted) && (
													<div className="devices_info">
														<img className="danger_icon" src={danger} alt="main" />
														<p className="no_calls">Нет возможности совершать звонки</p>
														<p className="devices_info_text">
															Ваш микрофон или камера не подключены или недоступны. Чтобы совершать
															звонки разрешите их использование в настройках браузера и обновите
															страницу
														</p>
													</div>
												)}
											</>
										)}
									</>
								)}
							</div>
						</div>
						<div className="controls_wrapper">
							<Controls
								showLocalVideo={showLocalVideo}
								sendVideo={sendVideo}
								currentCall={currentCall}
								handleCancelCall={handleCancelCall}
								micState={mic}
								setMicState={setMic}
								createCall={createCall}
								isPatient={isPatient}
								callStatus={callStatus}
								isMicrophoneAccessGranted={isMicrophoneAccessGranted}
								isCameraAccessGranted={isCameraAccessGranted}
							/>
						</div>
					</div>
				</div>
			</div>
			{/* )} */}

			<Row className={styles.NotificationsWrapper}>
				<div className={`${styles.Chats}`}>
					{room && sessionId ? (
						<TMKChatWidget roomId={room} sessionId={sessionId} tmkHumanFriendlyId={userInfoData?.appointment?.tmkHumanFriendlyId}/>
					) : (
						<Row className={styles.StubContainer}>
							<Image className={styles.Image} src={StubImage} preview={false} />

							<Typography>Здесь будет чат</Typography>
						</Row>
					)}
				</div>
			</Row>
		</>
	);
};

export default VoxImplantComponent;
